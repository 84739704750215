import ApiService from "@/core/services/api.service";

export default {
    getChickinAll(credentials) {
        ApiService.setHeader();
        return ApiService.query("v2/verifikasi/nasional/populasi/chickin/all", { params: credentials })
    },
    verifChickin(credentials) {
        ApiService.setHeader();
        return ApiService.put("v2/verifikasi/nasional/populasi/chickin", credentials)
    },

    getDeplesiAll(credentials) {
        ApiService.setHeader();
        return ApiService.query("v2/verifikasi/nasional/populasi/deplesi/all", { params: credentials })
    },
    verifDeplesi(credentials) {
        ApiService.setHeader();
        return ApiService.put("v2/verifikasi/nasional/populasi/deplesi", credentials)
    },

    getFeedInTakeAll(credentials) {
        ApiService.setHeader();
        return ApiService.query("v2/verifikasi/nasional/populasi/feedintake/all", { params: credentials })
    },
    verifFeedInTake(credentials) {
        ApiService.setHeader();
        return ApiService.put("v2/verifikasi/nasional/populasi/feedintake", credentials)
    },

    getTransferAll(credentials) {
        ApiService.setHeader();
        return ApiService.query("v2/verifikasi/nasional/populasi/transfer/all", { params: credentials })
    },
    verifTransfer(credentials) {
        ApiService.setHeader();
        return ApiService.put("v2/verifikasi/nasional/populasi/transfer", credentials)
    },

    getAfkirAll(credentials) {
        ApiService.setHeader();
        return ApiService.query("v2/verifikasi/nasional/populasi/afkir/all", { params: credentials })
    },
    verifAfkir(credentials) {
        ApiService.setHeader();
        return ApiService.put("v2/verifikasi/nasional/populasi/afkir", credentials)
    },

    getProduksiEggAll(credentials) {
        ApiService.setHeader();
        return ApiService.query("v2/verifikasi/nasional/populasi/produksi-egg/all", { params: credentials })
    },
    verifProduksiEgg(credentials) {
        ApiService.setHeader();
        return ApiService.put("v2/verifikasi/nasional/populasi/produksi-egg", credentials)
    },
    
    
    getPerbaikanChickinAll(credentials) {
        ApiService.setHeader();
        return ApiService.query("v2/verifikasi/perbaikan/nasional/populasi/chickin/all", { params: credentials })
    },
    verifPerbaikanChickin(credentials) {
        ApiService.setHeader();
        return ApiService.put("v2/verifikasi/perbaikan/nasional/populasi/chickin", credentials)
    },

    getPerbaikanDeplesiAll(credentials) {
        ApiService.setHeader();
        return ApiService.query("v2/verifikasi/perbaikan/nasional/populasi/deplesi/all", { params: credentials })
    },
    verifPerbaikanDeplesi(credentials) {
        ApiService.setHeader();
        return ApiService.put("v2/verifikasi/perbaikan/nasional/populasi/deplesi", credentials)
    },

    getPerbaikanFeedInTakeAll(credentials) {
        ApiService.setHeader();
        return ApiService.query("v2/verifikasi/perbaikan/nasional/populasi/feedintake/all", { params: credentials })
    },
    verifPerbaikanFeedInTake(credentials) {
        ApiService.setHeader();
        return ApiService.put("v2/verifikasi/perbaikan/nasional/populasi/feedintake", credentials)
    },

    getPerbaikanTransferAll(credentials) {
        ApiService.setHeader();
        return ApiService.query("v2/verifikasi/perbaikan/nasional/populasi/transfer/all", { params: credentials })
    },
    verifPerbaikanTransfer(credentials) {
        ApiService.setHeader();
        return ApiService.put("v2/verifikasi/perbaikan/nasional/populasi/transfer", credentials)
    },

    getPerbaikanAfkirAll(credentials) {
        ApiService.setHeader();
        return ApiService.query("v2/verifikasi/perbaikan/nasional/populasi/afkir/all", { params: credentials })
    },
    verifPerbaikanAfkir(credentials) {
        ApiService.setHeader();
        return ApiService.put("v2/verifikasi/perbaikan/nasional/populasi/afkir", credentials)
    },

    getPerbaikanProduksiEggAll(credentials) {
        ApiService.setHeader();
        return ApiService.query("v2/verifikasi/perbaikan/nasional/populasi/produksi-egg/all", { params: credentials })
    },
    verifPerbaikanProduksiEgg(credentials) {
        ApiService.setHeader();
        return ApiService.put("v2/verifikasi/perbaikan/nasional/populasi/produksi-egg", credentials)
    },



}